import { add } from "lodash";
import { createContext, useState, useEffect } from "react";

export const AddedEquipmentContext = createContext();

//This Context will store the selected Equipments
function AddedEquipmentContextProvider(props){
    const [addedEquipment, setAddedEquipment] = useState([]);
    const [totalConsumption, setConsumption] = useState(0);

    // useEffect(()=>{
    //     console.log('Rerendered')
    // },[addedEquipment])
    
    //Add Equipment to State
    function addEquipment(obj){
        // if(_.find(addedEquipment, { 'id': obj.id })){
        //     return false;
        // }else{
        //     setAddedEquipment(addedEquipment=>[...addedEquipment, obj])
        // }
        setAddedEquipment(addedEquipment=>[...addedEquipment, obj])
        // console.log('Array->' + JSON.stringify(addedEquipment))
    }
    //Remove Equipment to State
    function removeEquipment(obj,index){
        // var index = addedEquipment.indexOf(obj)
        // console.log(index);
        // const newArr = delete addedEquipment[index];
        // console.log("newarr:", newArr );
        // const newItems = addedEquipment.filter((item, i) => item.id !== obj.id)
        // console.log('Array? '+JSON.stringify(addedEquipment))
        // const newItems = addedEquipment.filter((item, i) => i!==index)
        // setAddedEquipment(newItems);
        setAddedEquipment(addedEquipment.filter((item, i) => i!==index))
        // console.log('Array2? '+JSON.stringify(addedEquipment))

        
        // let eqtCopy = [...addedEquipment];
        // addedEquipment.splice(index,1);
        // let removeEqt = addedEquipment;
        // console.log('Array? '+addedEquipment)
        // setAddedEquipment(addedEquipment.slice(0,obj.id).concat(addedEquipment.slice((obj.id)+1)))
        // var index = addedEquipment.indexOf(obj)
        
    }

    //Reset
    function resetEquipments(){
        // console.log('newItems', newItems)
        setAddedEquipment([]);
    }

    //Update Puissance/Usage of the Object in State
    function updateUsage(id, val){
        let arrayCopy = [...addedEquipment];
        let selectedArray = arrayCopy.filter((item) => item.id === id);
        if(selectedArray.length>0){
            let selected=selectedArray[0];
            selected.usage=val;
            setAddedEquipment(arrayCopy);
        }
    }

    function setTotalConsumption(value)
    {
        setConsumption(value)
    }

    function updateSelectedOption(id, quantity, usage, option, total, optionId) {
        const selectedOption = { quantity, usage, option, total, optionId}
        
        let arrayCopy = [...addedEquipment];
        let selectedArray = arrayCopy.filter((item) => item.id === id);
        if(selectedArray.length>0){
            let selected=selectedArray[0];
            selected.selectedOption=selectedOption;
            setAddedEquipment(arrayCopy);
        }
    }

    const value = {addedEquipment, totalConsumption, addEquipment, removeEquipment, updateUsage, updateSelectedOption, resetEquipments, setTotalConsumption}
    
    return (
        <AddedEquipmentContext.Provider value={value}>
            {props.children}
        </AddedEquipmentContext.Provider>
    );
}

export default AddedEquipmentContextProvider;