import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Row, Col } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from "sweetalert2";
import { AddedEquipmentContext } from './../../context/addedEquipmentContext';

const ProductModal = ({ isOpen, handleClose, product }) => {

    // Use the useState hook to create state variables for each field
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const {addedEquipment, addEquipment} = useContext(AddedEquipmentContext)
    const [formData, setFormData] = useState({ name: '', phone: '', society: '', address: '', email: '', message: '', product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment })
    // console.log('addedEquipment', addedEquipment)
    // This function will be called when the user submits the form
    const handleSubmit = (event) => {
        setErrors({})
        setLoading(true)
        if (validate() === true) {
            setLoading(true)
            // Prevent the default form submission behavior
            event.preventDefault();
            setFormData({ ...formData, product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment })

            //console.log('formData', formData)
            // Do something with the form values, like send them to an API
            const URL = 'https://devis.greenline-madagascar.com/api/'
            // const URL = 'http://127.0.0.1:8000/api/'
            axios.post(URL+'submitSimulatorContactDetails', formData)
                .then((res) => {
                    if (res && res?.data) {
                        handleClose()
                        setLoading(false)
                        setFormData({ name: '', phone: '', society: '', address: '', email: '', message: '', product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment })
                        Swal.fire('La demande a été soumise avec succès !', '', 'success')
                    }
                })
                .catch((error) => {
                    setLoading(false)
                })
        }
    };

    const validate = () => {
        if (formData.name === undefined || formData.name === '' || formData.name === null) {
            setErrors({ ...errors, name: 'Le nom est requis' })
            setLoading(false)
            return false
        }
        if (formData.email === undefined || formData.email === '' || formData.email === null) {
            setErrors({ ...errors, email: "L'e-mail est requis" })
            setLoading(false)
            return false
        }
        if (!isValidEmail()) {
            setErrors({ ...errors, email: "Email invalide" })
            setLoading(false)
            return false
        }
        if (formData.phone === undefined || formData.phone === '' || formData.phone === null) {
            setErrors({ ...errors, phone: 'Le téléphone est requis' })
            setLoading(false)
            return false
        }
        // if (formData.society === undefined || formData.society === '' || formData.society === null) {
        //     setErrors({ ...errors, society: 'La société est nécessaire' })
        //     setLoading(false)
        //     return false
        // }
        setLoading(false)
        return true;
    }

    const isValidEmail = () => {
        return /\S+@\S+\.\S+/.test(formData.email);
    }

    useEffect(() => {
        setErrors({})
        setFormData({ name: '', phone: '', society: '', address: '', email: '', message: '', product: product?.name, watt: `${product?.puissance}W`, equipments: addedEquipment })
    }, [isOpen])

    return (
        <Form method="POST">
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="lg"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${product?.name} - ${product?.puissance}W`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row style={{ marginBottom: 10 }}>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Nom *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData?.name}
                                    placeholder='Nom'
                                    onChange={(event) => setFormData({ ...formData, name: event.target.value })}
                                    required
                                />
                                <span className='text-danger'>{errors?.name}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>E-mail *</Form.Label>
                                <Form.Control
                                    placeholder='E-mail'
                                    type="email"
                                    value={formData?.email}
                                    onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                                />
                                <span className='text-danger'>{errors?.email}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Téléphone *</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder='Téléphone'
                                    value={formData?.phone}
                                    onChange={(event) => setFormData({ ...formData, phone: event.target.value })}
                                />
                                <span className='text-danger'>{errors?.phone}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Société</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Société'
                                    value={formData?.society}
                                    onChange={(event) => setFormData({ ...formData, society: event.target.value })}
                                />
                                <span className='text-danger'>{errors?.society}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control value={formData?.address} type="text" as="textarea" rows={3} placeholder="Adresse"
                            onChange={(event) => setFormData({ ...formData, address: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Message</Form.Label>
                        <Form.Control value={formData?.message} type="text" as="textarea" rows={3} placeholder="Message"
                            onChange={(event) => setFormData({ ...formData, message: event.target.value })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>Annuler</Button>
                    {!loading ? <Button type="submit" disabled={loading} onClick={(e) => handleSubmit(e)}>
                        Soumettre
                    </Button> : <Button disabled={loading}>
                        <Spinner animation="border" size="sm" variant="light" style={{ marginRight: 5 }} />Chargement...
                    </Button>}

                </Modal.Footer>
            </Modal>
        </Form>
    )
}

export default ProductModal