import React, { useState, useContext, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { v4 } from "uuid";
import AddedEquipment from "./addedEquipment";
import data from "../../api/greelineProducts.json";
import { AddedEquipmentContext } from "../../context/addedEquipmentContext";
import Button from "react-bootstrap/Button";
import { ProductContext } from "./../../context/productContext";
import ProductModal from "./../modals/productModal";

function AddedEquipments(props) {
  const {
    addedEquipment,
    addEquipment,
    resetEquipments,
    setTotalConsumption,
    removeEquipment,
  } = useContext(AddedEquipmentContext);
  const { product } = useContext(ProductContext);
  const [showModal, setShowModal] = useState(false);
  const [consumption, setConsumption] = useState(0);
  const [productData, setProductData] = useState(data ? data[0] : {});

  useEffect(() => {
    setProductData(data[product]);
  }, [product]);

  useEffect(() => {
    // console.log('AddedEqpt->'+JSON.stringify(addedEquipment))
    let aeCount = 0;
    addedEquipment.map((aE) => {
      aeCount += parseFloat(aE.usage);
    });
    setConsumption(aeCount);
    setTotalConsumption(aeCount);
  }, [addedEquipment, product]);



  const reset = () => {
    resetEquipments();
  };

  return (
    <div className="addedEquipments">
      {addedEquipment?.length > 0 && (
        <div className="paddedRow">
          <Row className="mainRow">
            {addedEquipment?.map((equipment, index) => (
              <Row>
                {/* Selected Equipment Component */}
                <div className="rowInline">
                  <div className="leftt">
                    {/* {console.log("Added Index: "+ index)} */}
                    {/* {console.log("Equipment: "+ JSON.stringify(equipment))} */}
                    <AddedEquipment equipment={equipment} index={index} />
                  </div>
                </div>
              </Row>
            ))}
          </Row>
        </div>
      )}
      <div className="blueBG">
        <Row>
          <Col>
            <p className="consumptionHeading">
              {`Votre consommation électrique ${consumption} (en Wh/j)`}
            </p>
          </Col>
        </Row>
        <Row>
          {addedEquipment?.length > 0 && (
            <Col xs={12}>
              <Button
                style={{ margin: "auto", bottom: 0 }}
                size="sm"
                variant="warning"
                onClick={() => {
                  reset();
                }}
              >
                Réinitialiser
              </Button>

              <Button
                disabled={consumption > productData?.puissance}
                style={{ margin: "auto", bottom: 0, marginLeft: 5 }}
                size="sm"
                variant="primary"
                onClick={() => {
                  consumption <= productData?.puissance && setShowModal(true);
                }}
              >
                Obtenir un devis
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          {addedEquipment?.length == 0 && (
            <Col xs={12} style={{ color: "white" }}>
              0 équipements sélectionnés
            </Col>
          )}
        </Row>
      </div>
      <ProductModal
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        product={productData}
      />
    </div>
  );
}

export default AddedEquipments;
